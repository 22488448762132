/* NEW LANDING PAGE DESKTOP STYLES START HERE*/

#landing_page_outer {
    width: 100vw;
    z-index: -1;
    margin: 0;
    padding: 1em;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.landing_page_navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 86%;
    margin: auto;
    position: static;
    position: static;
}

.landing_page_navbar_text_logo {
    font-family: var(--tf_logo);
    font-size: 3em;
    color: var(--c_logo_royal_blue);
    margin-top: -0.3em;
    margin-bottom: -0.3em;
    padding: 0;
}

.landing_page_navbar_image_logo {
    height: 3em;
}

.landing_page_navbar_links {
    font-family: var(--tf_body);
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 22em;
}

.landing_page_burger_menu {
    display: none;
}

.landing_page_navbar_link {
    text-decoration: none;
    color: var(--tc_light_background_subtext);
    transition-duration: 0.5s;
    position: relative;
}

.landing_page_navbar_link::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: var(--tc_light_background_subtext);
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.2s ease-in-out 0s;
    color: var(--tc_light_background_subtext);
}

.landing_page_navbar_link:hover::before {
    color: var(--tc_light_background_subtext);
    text-decoration: underline;
    visibility: visible;
    transform: scaleX(1);
}

.landing_page_navbar_link:hover {
    color: var(--tc_light_background_subtext);
}

.landing_page_upper_body {
    display: flex;
    justify-content: center;
    width: 90vw;

    margin: auto;
    margin-top: 1em;
    margin-bottom: 1.5em;

    border-radius: 1.5em;

    background-image: url(LandingPageBackground.svg);
    background-size: cover;
    background-position: center center;
}

.landing_page_text_container {
    flex-basis: 66.66%;
}

.landing_page_text_box_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.landing_page_title_text {
    font: "Poppins";
    font-weight: 700;
    font-size: 3.5em;
    color: var(--c_logo_navy);
}

.landing_page_title_text_highlight {
    color: white;
}

.landing_page_mailchimp_form {
    margin-top: 2em;
}

#mailchimp_submit_button {
    background-color: var(--c_logo_royal_blue);
    border: none;
    color: var(--c_highlight_yellow);
    font-family: var(--tf_header);
    font-weight: 800;
    letter-spacing: 0.05em;
    padding-top: 0.3em;
    margin-top: 1em;
    height: 3.5em;
    width: 12.5em;
    border-radius: 0.5em;
    box-shadow: 0px 0px 0.85em rgba(0, 0, 0, 0.2);
}

#mailchimp_text_box {
    border: 0.1em solid var(--landing_textbox_border);
    font-family: var(--tf_header);
    letter-spacing: 0.01em;
    font-size: 1.15em;
    height: 3em;
    width: 25em;
    padding-top: 0.075em;
    padding-left: 1.2em;
    font-family: var(--tf_body);
    font-weight: 600;
    border-radius: 0.5em;
}

#mailchimp_text_box:focus {
    outline: none;
}

.mailchimp_message {
    color: var(--c_logo_navy);
    font-family: var(--tf_body);
    font-weight: 600;
    position: absolute;
    width: 12.5em;
    text-align: center;
    margin-top: 0.75em;
}

.landing_page_image_container {
    flex-basis: 33.33%;
}

.landing_page_image_box {
    /*    margin-right: 4em; /* Minimum distance from right edge */
    display: flex;
    text-align: right; /* Start it on the right */
}

.landing_page_upper_body_footer {
    display: none;
}

.landing_page_image {
    height: 40em;
    margin-top: 1.5em; /* For adding to the colorful box behind */
    margin-bottom: 1.5em;
    padding-top: 1em; /* For shrinking size of image */
    padding-bottom: 1em;
}

.landing_page_lower_body {
    display: none;
}

.landing_page_testimonials_wrapper {
    width: 90vw;
    margin: auto;
}

.landing_page_testimonials_title {
    margin-top: -.25em;
    color: var(--c_logo_royal_blue);
}

.landing_page_testimonials_container {
    display: flex;
    gap: 3em;
    margin-top: 1em;
    align-items: stretch;
}

.landing_page_testimonials_polaroid_container {
    align-self: flex-start;
    background-color: var(--bc_surface_accent);
    padding: 1em 1em 0.5em; /* top | left and right | bottom */
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.1); /* Horizontal offset, Vertical offset, Blur radius, Spread radius, Colour */
}

.landing_page_testimonials_photo {
    border-radius: 1em;
    height: 20em;
}

.landing_page_testimonials_names {
    color: var(--c_logo_navy);
    font-size: 1.5em;
    font-weight: 800;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0.25em 0em 0.25em; /* top | left and right | bottom */
}

.landing_page_testimonials_names_subtext {
    font-size: 0.7em;
}

.landing_page_testimonials_text {
    color: var(--c_logo_navy);
    font-size: 1.5em;
    font-weight: 800em;
    text-align: left;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
}

.landing_page_testimonials_text_divider {
    margin: 2em;
    background-color: var(--c_logo_navy);
    height: 0.1em;
    width: 3em;
}

.landing_page_testimonials_text_divider {
    margin: 1em;
}

.landing_page_testimonials_text_highlight {
    color: var(--c_logo_royal_blue);
}

.landing_page_testimonials_who_from {
    color: var(--tc_landing_light_background);
    text-align: right;
}

.landing_page_features_schools_wrapper {
    display: flex;
    width: 90vw;
    margin: auto;
    justify-content: space-between;
}

.landing_page_gray_textbox {
    flex-basis: 48%;
    background-color: var(--bc_landing_box);
    color: var(--tc_landing_light_background);
    font-family: var(--tf_body);
    font-weight: 600;
    border-radius: 1.5em;
    padding: 2em;
    margin-bottom: 1.5em;
}

.landing_page_gray_textbox_header {
    font-size: 1.2em;
}

.landing_page_gray_box_logos_wrapper {
    display: flex;
    align-items: center;
}

.landing_page_gray_box_logo {
    margin: 1em;
    margin-left: 1.5em;
}

.landing_page_footer {
    margin: auto;
    width: 100%;
    text-align: center;
    bottom: 0;
    font-family: var(--tf_body);
    font-weight: 600;
    color: var(--tc_light_background_subtext);
}

.landing_page_footer_highlight {
    color: var(--c_logo_royal_blue);
}

@media (max-width: 1100px) {
    #landing_page_outer {
        height: auto;
    }
    .landing_page_navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;
    }

    .landing_page_navbar_text_logo {
        font-size: 2.5em;
    }

    .landing_page_navbar_image_logo {
        width: 2.5em;
    }

    .landing_page_navbar_link {
        display: none;
    }
    .landing_page_navbar_links {
        width: 3em;
    }

    .landing_page_burger_menu {
        width: 2.5em;
        display: block;
    }

    .landing_page_text_container {
        padding-top: 1em;
        padding-bottom: 0.5em;
    }

    .landing_page_title_text {
        color: var(--bc_surface_accent);
        text-align: center;
        font-size: 3em;
    }

    .landing_page_mailchimp_form {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    #mailchimp_text_box {
        height: 3em;
        padding-top: 0.075em;
        padding-left: 1.2em;
        font-weight: 500;
        border-radius: 0.5em;
        width: 15em;
    }

    #mailchimp_submit_button_wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #mailchimp_submit_button {
        width: 17em;
    }

    .mailchimp_message {
        width: 17em;
        position: relative;
    }

    .landing_page_upper_body_footer {
        display: block;
        color: var(--bc_surface_accent);
        font-family: var(--tf_body);
        font-weight: 600;
        text-align: center;
        margin-top: 1.1em;
    }

    .landing_page_desktop_image_container {
        display: none;
    }

    .landing_page_lower_body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90vw;

        margin: auto;
        margin-top: 2em;
        margin-bottom: 1.5em;

        padding-bottom: 1em;

        border-radius: 1.5em;

        background-color: var(--bc_landing_box);
    }

    .landing_page_image {
        height: 40em;
        margin-bottom: 0;
    }

    .landing_page_lower_body_text {
        font-family: var(--tf_header);
        color: var(--c_logo_royal_blue);
        font-size: 1.5em;
    }
    
    .landing_page_testimonials_container {
        flex-direction: column;
    }
    
    .landing_page_testimonials_title {
        margin-top: -.1em;
        line-height: 1em;
    }

    .landing_page_testimonials_polaroid_container {
        align-self: center;
        padding: 1em 1em 0.5em; /* top | left and right | bottom */
    }

    .landing_page_testimonials_text {
        margin-top: -1em;
    }
    
    .landing_page_testimonials_names {
        color: var(--c_logo_navy);
        font-size: 1.5em;
        font-weight: 800;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        padding: 0.25em 0em 0.25em; /* top | left and right | bottom */
    }

    .landing_page_testimonials_names_subtext {
        font-size: 0.7em;
    }

    .landing_page_features_schools_wrapper {
        flex-direction: column;
    }

    .landing_page_gray_textbox {
        text-align: center;
    }

    .landing_page_gray_box_logos_wrapper {
        margin-left: 0;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .landing_page_footer_highlight {
        display: none;
    }
}
