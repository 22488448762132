@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Poppins:wght@700&display=swap);
@import url(https://use.typekit.net/noz0emw.css);
/* Used Google bc Adobe doesn't have Inter */
/* Aidan's UCLA adobe fonts account */

:root {
  /* deprecated colors */
  --surface_color: #376F87;
  --outline_color: #295365;
  --box_background_color_dark: #2e5c70;
  --box_background_color: #3883A3;
  --white_hover_color: #FBFBFB;
  --text_light_color: #FFFFFF;
  --brand_color: #93D4F0;
  --landing_dark_blue: #2C5B6F;
  --landing_dark_blue_light: #3A7D94;
  --dark_gray: #cccccc;
  --dark_gray_2: #858585;
  --title_family: 'Poppins';
  --logo_family: 'Aller';
  --text_family: 'Inter';

  /* c_ indicates color */
  --c_logo_navy: #2C5B6F;
  --c_logo_royal_blue: #29ABE2;
  --c_red_negative: #C74945;
  --c_red_negative_highlighted: #C74F4C;
  --c_green_positive: #5AA364;
  --c_highlight_yellow: #FFF200;

  /* bc is background color */
  --bc_surface: #F5F5F7;
  --bc_surface_accent: #FFFFFF;
  --bc_card: #29ABE2;
  --bc_active_button: #FFFFFF; /* tied to bc_surface_accent */
  --bc_hover_button: #F5F5F7; /* tied to bc_surface */
  --bc_disabled_button: #93C9E4;
  --bc_landing_box: #F2F6F7;

  /* tc_ is text color */
  --tc_light_background: #000000;
  --tc_light_background_subtext: #6E6E73;
  --tc_dark_background: #FFFFFF;
  --tc_dark_background_subtext: #E4F2F9;
  --tc_dark_background_disabled: #CCCCCC;
  --tc_disabled_button: #5DAFD6;
  --tc_landing_light_background: #888E9D;

  /* tf_ is text font family */
  --tf_header: 'Poppins';
  --tf_logo: 'Aller';
  --tf_body: 'Inter';

  /* custom colors */
  --landing_textbox_border: #cccccc;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue' 'PT Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  background-color: var(--bc_surface);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App_image_background { /*conditionally render this and another background image based on if the page needs to scroll or how the image works*/
  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: url(/static/media/LandingPageBackground.db002e5d.svg);
  background-size: cover;
  background-attachment: fixed;
  position: fixed;
  background-position: center center;
  margin: 0;
}

.App_color_background {
  z-index: -1;
  background-color: var(--bc_surface);
  background-position: center center;
  margin: 0;
}

.App_swirl_background {
  background-color: var(--bc_surface);
  background-position: center center;
  margin: 0; 
  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: url(/static/media/DiagonalSwirl.37d71d1d.svg);
  background-size: cover;
  background-attachment: fixed;
  position: fixed
}

#matches {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: auto;
  width: 100%;
}

#no_matches_page {
  font-family: var(--title_family);
  display: flex;
  justify-content: center; 
}

#no_matches_box {
  background-color: var(--box_background_color);
  border-radius: 1.25rem;
  width: 20rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: fixed;
}

#no_matches_text {
  color: var(--text_light_color);
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
}

#match_card_button {
  border: none;
  margin: auto;
  background-color: var(--bc_surface);
  margin-bottom: .5rem;
}

.match_preview_box {
  width: 20rem;
  height: 7.25rem;
  border-radius: 10rem;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  background-color: var(--bc_surface_accent);
  margin: auto;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.match_preview_photo {
  border-radius: .75rem;
  width: 7.25rem;
  height: auto
}

.match_preview_name {
  color: var(--tc_light_background);
  font-family: var(--tf_header);
  font-size: 1.1rem;
}

.match_preview_subtext {
  color: var(--tc_light_background_subtext);
  font-family: var(--tf_header);
  font-size: 0.9rem;
}

.match_preview_date {
  color: var(--tc_light_background_subtext);
  font-family: var(--tf_header);
  font-size: .75rem;
}

.match_preview_text {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-content: flex-start;
}

.match_preview_text_top {
  justify-self: flex-start;
  margin-top: .5rem;
}

.match_preview_text_bottom {
  justify-self: flex-end;
  margin-bottom: .5rem;
}

.matches_title_box {
  margin-bottom: 1rem;
}
#login_page {
    background-color: transparent;
}

#entrance_page {
    background-color: transparent;
}

#sign_in_card_arrangement {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#sign_in_page_arrangement {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: flex-start;
}

.signup_title {
    font-family: var(--tf_header);
    color: var(--tc_dark_background);
    font-size: 1.5rem;
    padding-top: 1rem;
}

#signin_input_fields {
    margin-top: .5rem;
    margin-bottom: 1.2rem;
}

.signup_subtext {
    font-family: var(--tf_body);
    font-weight: 500;
    font-size: .9rem;
    color: var(--tc_dark_background_subtext);
}

#termsconditions_outer {
    margin-left: 2rem;
    margin-right: 1rem;
    text-align: left;
    display: flex;
    margin-bottom: 1rem;
}

#termsconditions_text {
    margin-left: .5rem;
}

.termsconditions_checkbox {
    border: none;
}

.termsconditions_links {
    text-decoration: none;
    color: var(--tc_dark_background);
    font-weight: 600;
}

.termsconditions_links:hover {
    text-decoration: underline;
    color: var(--tc_dark_background);
}

#signin_switch_pages_text {
    margin-top: .75rem;
    font-family: var(--tf_body);
    font-weight: 600;
    color: var(--tc_dark_background)
}

#signin_switch_pages_link {
    color: var(--c_logo_royal_blue);
    font-weight: 600;
    background-color: transparent;
    border: none;
}

#signin_switch_pages_link:hover {
    text-decoration: underline;
}

#signin_help_text {
    margin-top: 0;
    font-family: var(--tf_body);
    font-weight: 600;
    color: var(--tc_dark_background)
}

#signin_help_link {
    color: var(--c_logo_royal_blue);
    font-weight: 600;
    text-decoration: none;
}

#signin_help_link:hover {
    text-decoration: underline;
}

#signin_logo {
    width: 3.5rem;
    height: 3.5rem;
    margin-bottom: 1.5rem;
}

.signin_alert {
    width: 20rem;
    border: none;
}

#login_prompt_box {
    background-color: var(--box_background_color);
    text-align: center;
    height: 7rem;
    width: 20rem;
    margin: auto;
    border-radius: 1.25rem;

    margin-top: 2rem;
}

#login_prompt_title {
    font-family: var(--title_family);
    color: var(--text_light_color);
    font-size: 1.3rem;
    padding-top: 1rem;
}

.lifestyle_prefs_title {
    font-family: var(--title_family);
    font-size: 1rem;
    padding-top: 1rem;
    padding-left: .5rem;
    padding-right: .5rem;
}

.signup_input_box {
    background-color: var(--surface_color);
    color: var(--text_light_color);
    border-radius: .4rem;
    border: .095rem solid var(--outline_color);
    margin-top: .5rem;
    width: 15rem;
    height: 2.2rem;
    padding-left: .75rem;
    padding-right: .75rem;

    font-family: var(--text_family);
    font-weight: 600;
    font-size: .9rem;
}

.signup_input_box:disabled {
    color: var(--box_background_color);
    border-color: var(--surface_color);
}

.signup_input_box_error {
    border: .095rem solid rgba(255, 0, 0, 0.5);
}

.signup_input_box::-webkit-input-placeholder {
    color: var(--dark_gray);
}

.signup_input_box:-ms-input-placeholder {
    color: var(--dark_gray);
}

.signup_input_box::placeholder {
    color: var(--dark_gray);
}

.signup_input_box:focus {
    outline: none;
    border: .1rem solid var(--brand_color);
}

#signup_login_despacer {
    margin-top: -1.5rem;
}

#photo_upload_page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#pics_upload_icon {
    display: none;
}

#photo_upload_submit_button {
    width: 20rem;
    margin-top: .75rem;
}

#upload_photo_button {
    margin-top: .75rem;
    width: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: .1s;
}

#upload_photo_button:hover {
    transform: scale(1.0175);
    transition-duration: .2s;
}

.crop_ui {
    border-radius: .35rem;
}

.crop_ui_box {
    margin: 1rem;
    margin-bottom: -0.5rem;
}

.cropped_thumbnail {
    border-radius: .35rem;
    width: 18rem;
}

.cropped_thumbnail_box {
    margin: 1rem;
    margin-bottom: 0;
}

.photo_upload_spacer {
    padding: 1rem;
}
#extended_bio_input_box {
    height: 15rem;
    word-wrap: break-word;
    resize: none;
    padding: .5rem;
}

#bio_spacer {
    margin: 1rem;
}
#basic_info_spacer {
    margin: 1rem;
}

#basic_info_alert {
    border: none;
    width: 17.5rem;
    font-family: var(--tf_body);
    margin: auto;
    margin-top: .5rem;
    margin-bottom: .5rem;
    /* border-radius: .75rem; */
}
#basic_info_subtext {
    margin-bottom: .5rem;
}

#basic_info_helptext {
    margin-top: .5rem;
    margin-bottom: 0;
    padding: 0;
}
#lifestyle_survey_page {
    width: 20rem;
    margin: auto;
}

.lifestyle_prefs_title_box {
    display: flex;
}

.lifestyle_prefs_title {
    color: var(--tc_dark_background);
    text-align: center;
    margin: auto;
    margin-top: .3rem;
    width: 18rem;
    font-size: 1.05rem;
}

#lifestyle_submit_button {
    margin-top: 2rem;
}

#lifestyle_question_count_label_full_list {
    text-align: right;
    margin-right: 2rem;
    margin-top: .3rem;
    margin-bottom: -.75rem;
    font-size: .7rem;
}

#lifestyle_question_count_label_short_list {
    font-size: .8rem;
    font-weight: 600;
}

#lifestyle_weights_outer_box {
    margin-top: 1rem;
}

#lifestyle_horizontal_box {
    display: flex;
    justify-content: space-between;
    width: 17rem;
    margin: auto;
    margin-top: .65rem;
}

#lifestyle_radio_button_label_box {
    display: flex;
    flex-direction: column;
}

#lifestyle_radio_label {
    font-size: .9rem;
    font-family: 'Inter';
    font-weight: 600;
    margin-top: .175rem;
}

#lifestyle_arrows_question_count_box {
   display: flex;
   justify-content: space-around;
   align-items: center;
   width: 20rem;
   margin-top: 1.5rem;
   margin-bottom: -1.25rem;
   font-family: 'Inter';
}

.lifestyle_arrow_box {
    padding: .5rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    border: none;
    background-color: transparent;
    color: var(--tc_dark_background);
    font-size: 2rem;
    font-weight: 600;
}

.lifestyle_arrow_box_highlighted {
    color: var(--tc_dark_background);
}

.lifestyle_arrow_box_disabled {
    color: var(--c_logo_royal_blue);
}
#top_navbar {
    background-color: var(--bc_surface_accent);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    width: 100%;
    z-index: 100;
}

.top_navbar_item {
    color: var(--tc_light_background_subtext);
    font-family: var(--text_family);
    font-weight: 600;
    text-decoration: none;
    font-size: .9rem;
    justify-self: center;  

    margin: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    transition-duration: .30s;
}

.top_navbar_item:hover {
    color: var(--tc_light_background_subtext);
    transform: translate(0, -0.15rem);
    transition-duration: .30s;
  }

#top_navbar_brand_text {
    color: var(--c_logo_royal_blue);
    text-decoration: none;
    font-family: var(--tf_logo);
    font-size: 2rem;

    justify-self: center;    
    margin-left: 1rem;
    margin-right: 1rem;   
}

#top_navbar_space {
    padding-top: 6rem;
}

#bottom_navbar {
    background-color: var(--bc_surface_accent);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right:0;
    z-index: 100;
}

.bottom_navbar_svg {
    width: 2.25rem;
    height: 2.25rem;
    margin: .5rem;
    margin-left: 2rem;
    margin-right: 2rem;
    transition-duration: .20s;
    /* stroke: var(--c_logo_royal_blue);
    fill: var(--c_logo_royal_blue);
    color: var(--c_logo_royal_blue) */
}

.bottom_navbar_svg:hover {
    color: var(--c_logo_royal_blue);
    transform: translate(0, -0.15rem);
    transition-duration: .20s;
}

#bottom_navbar_space {
    padding: 2rem;
}

#top_header {
    background-color: var(--bc_surface_accent);
    text-align: center;
    padding: .8rem;
    width: 100%;
    z-index: 100;
    margin-bottom: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

#header_logo {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: .5rem;
}

#top_header_text {
    font-family: var(--tf_logo);
    color: var(--c_logo_royal_blue);
    font-size: 2rem;
}

#footer_absolute {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: .4rem;
    color: var(--c_logo_navy);
    font-family: var(--tf_body);    
}


#footer_relative {
    width: 100%;
    text-align: center;
    padding: .4rem;
    color: var(--c_logo_navy);
    font-family: var(--tf_body); 
}

#footer_random {
    font-size: .9rem;
    font-weight: 600;
}

#footer_body {
    font-size: .85rem;
    font-weight: 550;
}
#user_profile_page_box {
    display: flex;
    flex-direction: column;
    flex-direction: column;
    justify-content: flex-start;
    width: 20rem;
    margin: auto;
}

.user_profile_image_box {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 0.75rem;
    z-index: 1;
    position: relative;
    margin-bottom: -0.75rem;
}

#user_profile_name {
    font-family: var(--tf_header);
    font-weight: 700;
    font-size: 1.3rem;
    color: var(--c_logo_royal_blue);
}

#user_profile_pronouns_age {
    font-family: var(--tf_header);
    font-weight: 700;
    font-size: 1.3rem;
    color: var(--tc_light_background_subtext);
}

.user_profile_box {
    background-color: var(--bc_surface_accent);
    border-radius: 0.75rem;
    padding: 0.5rem;
    margin-bottom: 1rem;
}

#swipe_button_box {
    background-color: transparent;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: -0.5rem;
}

.user_profile_title {
    font-family: var(--tf_header);
    color: var(--tc_light_background);
    font-weight: 700;
    font-size: 1.07rem;
}

.user_profile_subtext {
    font-family: var(--tf_body);
    font-weight: 600;
    color: var(--tc_light_background_subtext);
}

.user_profile_edit_button {
    font-family: var(--tf_body);
    font-size: 1rem;
    text-decoration: none;
}

.user_profile_edit_button_unfilled {
    color: var(--c_red_negative);
}

.user_profile_edit_button_unfilled:hover {
    color: var(--c_red_negative_highlighted);
}

.user_profile_edit_button_filled {
    color: var(--tc_light_background_subtext);
}

.user_profile_edit_button_filled:hover {
    color: var(--tc_light_background);
}

#user_profile_photo_upload_edit_button {
    align-self: flex-end;
    font-weight: 600;
    z-index: 5;
    margin-bottom: 0.1rem;
    margin-top: 0.75rem;
}

#lifestyle_survey_display_box {
    margin-bottom: 1rem;
}

#lifestyle_survey_display_q {
    font-family: var(--tf_body);
    font-weight: 600;
    color: var(--tc_light_background);
    margin-top: 0.1rem;
}

.lifestyle_survey_display_text {
    font-family: var(--tf_body);
    font-weight: 600;
    color: var(--tc_light_background_subtext);
}

#swipe_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
}

#like_button {
    width: 4rem;
    transition-duration: 0.2s;
}

#like_button:hover {
    transition-duration: 0.2s;
    transform: scale(1.03);
}

#dislike_button {
    width: 4rem;
    transition-duration: 0.2s;
}

#dislike_button:hover {
    transition-duration: 0.2s;
    transform: scale(1.03);
}

.swipe_buttons {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
}

#swipe_page_profile_space {
    padding: 1rem;
}

.dislike_button_wrapper {
    border: none;
    padding: 0;
    background-color: var(--surface-color);
    margin-left: 2.5rem;
}

.like_button_wrapper {
    border: none;
    padding: 0;
    background-color: var(--surface-color);
    margin-right: 2.5rem;
}

#user_profile_back_button {
    border: none;
    color: var(--tc_light_background_subtext);
    font-weight: 500;
    background-color: var(--surface-color);
    font-family: var(--tf_body);
}

#swipe_animation_wrapper {
    margin: auto;
    width: 20rem;
    z-index: 100;
}

.user_profile_image_no_swipe {
    background-color: transparent;
    position: absolute;
    z-index: 2;
}

.user_profile_image_swipe_right {
    background-color: #5aa364;
    padding-left: 10rem;
    padding-right: 10rem;
    margin-top: -10rem;
    padding-bottom: 100rem;
    position: absolute;
    z-index: 2;
    animation: swipe 0.6s ease-out;
    border-radius: 0.5rem;
}

.user_profile_image_swipe_left {
    background-color: var(--bc_surface);
    padding-left: 10rem;
    padding-right: 10rem;
    padding-bottom: 100rem;
    position: absolute;
    z-index: 2;
    animation: swipe 0.6s ease-out;
    border-radius: 0.5rem;
}

@keyframes swipe {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.user_profile_image_swipe_match {
    background-color: var(--c_logo_royal_blue);
    padding-left: 10rem;
    padding-right: 10rem;
    padding-bottom: 100rem;
    position: absolute;
    z-index: 2;
    animation: match-swipe 2s ease-out;
    border-radius: 0.5rem;
}

.user_profile_match_text_hidden {
    display: none;
}

.user_profile_match_text {
    display: block;
    position: absolute; /* Use absolute positioning to move the text freely */
    top: 50%; /* Center the text vertically */
    left: 50%; /* Start the text from the center */
    transform: translate(-50%, -50%); /* Ensure the text is centered */
    animation: match-text-slide 2s ease-out; /* Use the animation */
    z-index: 3; /* Make sure the text is on top of other elements */
    font-size: 2em; /* Adjust the font size as needed */
    color: white; /* Adjust the color as needed */

    font-family: var(--tf_header);
    font-weight: 600;
}

@keyframes match-swipe {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    70% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes match-text-slide {
    0% {
        color: var(--bc_surface);
        opacity: 0;
        top: -250%;
    }

    35% {
        color: var(--bc_surface_accent);
        opacity: 1;
        top: 50%;
    }

    60% {
        color: var(--bc_surface_accent);
        opacity: 1;
        top: 50%;
    }

    100% {
        color: var(--bc_surface);
        opacity: 0;
        top: 200%;
    }
}

#user_profile_color_overlay {
    border-radius: 0.25rem;
}

#survey_switch_button {
    border: none;
    background-color: var(--bc_surface_accent);
    font-family: var(--tf_body);
    color: var(--tc_light_background_subtext);
    font-weight: 500;
    text-decoration: underline;
}

#survey_switch_button_alt {
    border: none;
    background-color: var(--bc_surface_accent);
    font-family: var(--tf_body);
    color: var(--tc_light_background);
    font-weight: 500;
}

#choose_filter_button {
    width: 10rem;
    height: 2rem;
    margin: auto;
    margin-bottom: 1rem;
    margin-top: -1.25rem;
    border: none;
    border-radius: 0.75rem;
    background-color: var(--bc_surface_accent);
    color: var(--tc_light_background_subtext);
    font-family: "Inter";
    font-weight: 800;
    letter-spacing: 0.01rem;
}

#feedback_button {
    padding-left: 1rem;
    padding-right: 1rem;
    height: 2rem;
    margin: auto;
    border: none;
    border-radius: 0.75rem;
    background-color: var(--bc_surface_accent);
    color: var(--tc_light_background_subtext);
    font-family: "Inter";
    font-weight: 500;
    letter-spacing: 0.01rem;
}

.progress_bar_background {
    width: 19rem;
    height: 0.6rem;
    margin: auto;
    border-radius: 20rem;
    background-color: var(--bc_disabled_button);
    z-index: 1;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.progress_bar_completion {
    height: 0.6rem;
    border-radius: 20rem;
    background-color: var(--c_logo_royal_blue);
    z-index: 2;
}

.progress_bar_title {
    display: flex;
    justify-content: space-between;
}

.profile_two_column {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    grid-gap: 1rem;
    gap: 1rem;
}

.settings_button_container {
    background-color: var(--bc_surface_accent);
    padding: 0.75rem;
    border-radius: 0.75rem;
    transition-duration: 0.2s;
}

.refer_friend_button {
    background-color: var(--bc_surface_accent);
    font-family: var(--tf_body);
    font-weight: 600;
    color: var(--tc_light_background_subtext);
    width: 100%;
    border-radius: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.2s;
    cursor: pointer;
}

#no_swipes_available_page {
    font-family: var(--title_family);
    display: flex;
    justify-content: center;
}

#no_swipes_available_box {
    background-color: var(--box_background_color);
    border-radius: 1.25rem;
    width: 20rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

#no_swipes_available_text {
    color: var(--text_light_color);
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
}

#loader_outer_box {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 75vh; /*center the loader vertically by adjusting height of page*/
}

.filter_gradyear_checkboxes_container {
    display: flex;
    flex-wrap: wrap;  /* Allows the child items to wrap to the next row when they exceed the container's width */
    justify-content: center;  /* Spreads the child items out evenly */
    width: 100%;  /* Can adjust based on your needs */
}

.filter_gradyear_checkboxes_item {
    flex: 0 0 48%;  /* This makes sure that each checkbox takes up roughly half of the container's width minus some space for padding/margin */
    margin-bottom: 20px;  /* Space between rows */
}
/* NEW LANDING PAGE DESKTOP STYLES START HERE*/

#landing_page_outer {
    width: 100vw;
    z-index: -1;
    margin: 0;
    padding: 1em;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.landing_page_navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 86%;
    margin: auto;
    position: static;
    position: static;
}

.landing_page_navbar_text_logo {
    font-family: var(--tf_logo);
    font-size: 3em;
    color: var(--c_logo_royal_blue);
    margin-top: -0.3em;
    margin-bottom: -0.3em;
    padding: 0;
}

.landing_page_navbar_image_logo {
    height: 3em;
}

.landing_page_navbar_links {
    font-family: var(--tf_body);
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 22em;
}

.landing_page_burger_menu {
    display: none;
}

.landing_page_navbar_link {
    text-decoration: none;
    color: var(--tc_light_background_subtext);
    transition-duration: 0.5s;
    position: relative;
}

.landing_page_navbar_link::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -2px;
    left: 0;
    background-color: var(--tc_light_background_subtext);
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.2s ease-in-out 0s;
    color: var(--tc_light_background_subtext);
}

.landing_page_navbar_link:hover::before {
    color: var(--tc_light_background_subtext);
    text-decoration: underline;
    visibility: visible;
    transform: scaleX(1);
}

.landing_page_navbar_link:hover {
    color: var(--tc_light_background_subtext);
}

.landing_page_upper_body {
    display: flex;
    justify-content: center;
    width: 90vw;

    margin: auto;
    margin-top: 1em;
    margin-bottom: 1.5em;

    border-radius: 1.5em;

    background-image: url(/static/media/LandingPageBackground.db002e5d.svg);
    background-size: cover;
    background-position: center center;
}

.landing_page_text_container {
    flex-basis: 66.66%;
}

.landing_page_text_box_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.landing_page_title_text {
    font: "Poppins";
    font-weight: 700;
    font-size: 3.5em;
    color: var(--c_logo_navy);
}

.landing_page_title_text_highlight {
    color: white;
}

.landing_page_mailchimp_form {
    margin-top: 2em;
}

#mailchimp_submit_button {
    background-color: var(--c_logo_royal_blue);
    border: none;
    color: var(--c_highlight_yellow);
    font-family: var(--tf_header);
    font-weight: 800;
    letter-spacing: 0.05em;
    padding-top: 0.3em;
    margin-top: 1em;
    height: 3.5em;
    width: 12.5em;
    border-radius: 0.5em;
    box-shadow: 0px 0px 0.85em rgba(0, 0, 0, 0.2);
}

#mailchimp_text_box {
    border: 0.1em solid var(--landing_textbox_border);
    font-family: var(--tf_header);
    letter-spacing: 0.01em;
    font-size: 1.15em;
    height: 3em;
    width: 25em;
    padding-top: 0.075em;
    padding-left: 1.2em;
    font-family: var(--tf_body);
    font-weight: 600;
    border-radius: 0.5em;
}

#mailchimp_text_box:focus {
    outline: none;
}

.mailchimp_message {
    color: var(--c_logo_navy);
    font-family: var(--tf_body);
    font-weight: 600;
    position: absolute;
    width: 12.5em;
    text-align: center;
    margin-top: 0.75em;
}

.landing_page_image_container {
    flex-basis: 33.33%;
}

.landing_page_image_box {
    /*    margin-right: 4em; /* Minimum distance from right edge */
    display: flex;
    text-align: right; /* Start it on the right */
}

.landing_page_upper_body_footer {
    display: none;
}

.landing_page_image {
    height: 40em;
    margin-top: 1.5em; /* For adding to the colorful box behind */
    margin-bottom: 1.5em;
    padding-top: 1em; /* For shrinking size of image */
    padding-bottom: 1em;
}

.landing_page_lower_body {
    display: none;
}

.landing_page_testimonials_wrapper {
    width: 90vw;
    margin: auto;
}

.landing_page_testimonials_title {
    margin-top: -.25em;
    color: var(--c_logo_royal_blue);
}

.landing_page_testimonials_container {
    display: flex;
    grid-gap: 3em;
    gap: 3em;
    margin-top: 1em;
    align-items: stretch;
}

.landing_page_testimonials_polaroid_container {
    align-self: flex-start;
    background-color: var(--bc_surface_accent);
    padding: 1em 1em 0.5em; /* top | left and right | bottom */
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.1); /* Horizontal offset, Vertical offset, Blur radius, Spread radius, Colour */
}

.landing_page_testimonials_photo {
    border-radius: 1em;
    height: 20em;
}

.landing_page_testimonials_names {
    color: var(--c_logo_navy);
    font-size: 1.5em;
    font-weight: 800;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0.25em 0em 0.25em; /* top | left and right | bottom */
}

.landing_page_testimonials_names_subtext {
    font-size: 0.7em;
}

.landing_page_testimonials_text {
    color: var(--c_logo_navy);
    font-size: 1.5em;
    font-weight: 800em;
    text-align: left;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
}

.landing_page_testimonials_text_divider {
    margin: 2em;
    background-color: var(--c_logo_navy);
    height: 0.1em;
    width: 3em;
}

.landing_page_testimonials_text_divider {
    margin: 1em;
}

.landing_page_testimonials_text_highlight {
    color: var(--c_logo_royal_blue);
}

.landing_page_testimonials_who_from {
    color: var(--tc_landing_light_background);
    text-align: right;
}

.landing_page_features_schools_wrapper {
    display: flex;
    width: 90vw;
    margin: auto;
    justify-content: space-between;
}

.landing_page_gray_textbox {
    flex-basis: 48%;
    background-color: var(--bc_landing_box);
    color: var(--tc_landing_light_background);
    font-family: var(--tf_body);
    font-weight: 600;
    border-radius: 1.5em;
    padding: 2em;
    margin-bottom: 1.5em;
}

.landing_page_gray_textbox_header {
    font-size: 1.2em;
}

.landing_page_gray_box_logos_wrapper {
    display: flex;
    align-items: center;
}

.landing_page_gray_box_logo {
    margin: 1em;
    margin-left: 1.5em;
}

.landing_page_footer {
    margin: auto;
    width: 100%;
    text-align: center;
    bottom: 0;
    font-family: var(--tf_body);
    font-weight: 600;
    color: var(--tc_light_background_subtext);
}

.landing_page_footer_highlight {
    color: var(--c_logo_royal_blue);
}

@media (max-width: 1100px) {
    #landing_page_outer {
        height: auto;
    }
    .landing_page_navbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: auto;
    }

    .landing_page_navbar_text_logo {
        font-size: 2.5em;
    }

    .landing_page_navbar_image_logo {
        width: 2.5em;
    }

    .landing_page_navbar_link {
        display: none;
    }
    .landing_page_navbar_links {
        width: 3em;
    }

    .landing_page_burger_menu {
        width: 2.5em;
        display: block;
    }

    .landing_page_text_container {
        padding-top: 1em;
        padding-bottom: 0.5em;
    }

    .landing_page_title_text {
        color: var(--bc_surface_accent);
        text-align: center;
        font-size: 3em;
    }

    .landing_page_mailchimp_form {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    #mailchimp_text_box {
        height: 3em;
        padding-top: 0.075em;
        padding-left: 1.2em;
        font-weight: 500;
        border-radius: 0.5em;
        width: 15em;
    }

    #mailchimp_submit_button_wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #mailchimp_submit_button {
        width: 17em;
    }

    .mailchimp_message {
        width: 17em;
        position: relative;
    }

    .landing_page_upper_body_footer {
        display: block;
        color: var(--bc_surface_accent);
        font-family: var(--tf_body);
        font-weight: 600;
        text-align: center;
        margin-top: 1.1em;
    }

    .landing_page_desktop_image_container {
        display: none;
    }

    .landing_page_lower_body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90vw;

        margin: auto;
        margin-top: 2em;
        margin-bottom: 1.5em;

        padding-bottom: 1em;

        border-radius: 1.5em;

        background-color: var(--bc_landing_box);
    }

    .landing_page_image {
        height: 40em;
        margin-bottom: 0;
    }

    .landing_page_lower_body_text {
        font-family: var(--tf_header);
        color: var(--c_logo_royal_blue);
        font-size: 1.5em;
    }
    
    .landing_page_testimonials_container {
        flex-direction: column;
    }
    
    .landing_page_testimonials_title {
        margin-top: -.1em;
        line-height: 1em;
    }

    .landing_page_testimonials_polaroid_container {
        align-self: center;
        padding: 1em 1em 0.5em; /* top | left and right | bottom */
    }

    .landing_page_testimonials_text {
        margin-top: -1em;
    }
    
    .landing_page_testimonials_names {
        color: var(--c_logo_navy);
        font-size: 1.5em;
        font-weight: 800;
    
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    
        padding: 0.25em 0em 0.25em; /* top | left and right | bottom */
    }

    .landing_page_testimonials_names_subtext {
        font-size: 0.7em;
    }

    .landing_page_features_schools_wrapper {
        flex-direction: column;
    }

    .landing_page_gray_textbox {
        text-align: center;
    }

    .landing_page_gray_box_logos_wrapper {
        margin-left: 0;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .landing_page_footer_highlight {
        display: none;
    }
}

.burger {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 1.5em;
  width: 2.5em;
  z-index: 3;
}

.burger_line {
  width: 1.9em;
  height: .22em;
  background-color: var(--tc_light_background_subtext);
  transition: all 0.3s ease-in-out;
  z-index: 3;
}

.burger.open .burger_line:nth-child(1) {
  transform: translateY(.6375em) rotate(45deg);
}

.burger.open .burger_line:nth-child(2) {
  opacity: 0;
}

.burger.open .burger_line:nth-child(3) {
  transform: translateY(-.6375em) rotate(-45deg);
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--bc_surface_accent);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  overflow-x: hidden;
  transform: translateX(-100%);
  transition: 0.5s;
  padding-top: 60px;
}

.menu.open {
  transform: translateX(0);
}

.menu a {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  color: var(--tc_light_background_subtext);
  text-decoration: none;
  transition: 0.3s;
  margin-top: 20px;
}

.menu a:hover {
  color: var(--c_logo_royal_blue);
}

.no-scroll {
  overflow: hidden;
}
.signup_submit_button {
    font-family: var(--text_family);
    font-weight: 600;
    border-radius: 100rem;
    border: solid;
    width: 7.5rem;
    height: 1.9rem;
    color: var(--brand_color);
    background-color: var(--surface_color);
    border: 0.1rem solid var(--brand_color);
    margin: auto;
    margin-top: 0.75rem;
}

.signup_submit_button:disabled {
    color: var(--box_background_color);
    background-color: var(--surface_color);
    border: none;
}

.signup_submit_button:enabled:hover {
    border: 0.1rem solid var(--text_light_color);
    color: var(--text_light_color);
}

.default_roomble_button {
    font-family: var(-tf_body);
    font-weight: 600;
    border: none;
    border-radius: 0.35rem;
    width: 16.75rem;
    height: 2.25rem;
    background-color: var(--bc_active_button);
    color: var(--c_logo_royal_blue);
    margin: auto;
    margin-bottom: 0.5rem;
    transition-duration: 0.1s;
}

.default_roomble_button:disabled {
    background-color: var(--bc_disabled_button);
    color: var(--tc_disabled_button);
}

.default_roomble_button:enabled:hover {
    transform: scale(1.0175);
    transition-duration: 0.2s;
}

.highlighted_roomble_button {
    font-family: var(-tf_body);
    font-weight: 600;
    border: none;
    border-radius: 0.35rem;
    width: 16.75rem;
    height: 2.25rem;
    background-color: var(--bc_card);
    color: var(--bc_surface_accent);
    margin: auto;
    margin-bottom: 0.5rem;
    transition-duration: 0.1s;
}

.highlighted_roomble_button:disabled {
    background-color: var(--bc_disabled_button);
    color: var(--tc_disabled_button);
}

.highlighted_roomble_button:enabled:hover {
    transform: scale(1.0175);
    transition-duration: 0.2s;
}

.default_roomble_input_box {
    background-color: var(--bc_surface);
    color: var(--tc_light_background);
    border-radius: 0.35rem;
    border: none;
    margin-top: 0.5rem;
    width: 16.75rem;
    height: 2.2rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-family: var(--tf_body);
    font-weight: 600;
    font-size: 0.9rem;
}

input[type="radio"] {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    margin: auto;
    width: 1rem;
    height: 1rem;
    border: 0.15rem solid var(--tc_dark_background);
    border-radius: 50%;

    display: grid;
    place-content: center;
}

input[type="radio"]::before {
    content: "";
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1rem 1rem var(--tc_dark_background);
}

input[type="radio"]:checked::before {
    transform: scale(1);
}

.default_roomble_input_box_error {
    border: 0.095rem solid rgba(255, 0, 0, 0.5);
}

.default_roomble_input_box::-webkit-input-placeholder {
    color: var(--tc_light_background_subtext);
}

.default_roomble_input_box:-ms-input-placeholder {
    color: var(--tc_light_background_subtext);
}

.default_roomble_input_box::placeholder {
    color: var(--tc_light_background_subtext);
}

.default_roomble_input_box:focus {
    outline: none;
}

#highlighted_card {
    background-color: var(--bc_card);
    padding-bottom: 1rem;
    width: 20rem;
    margin: auto; /* for pages without flexbox */
    align-items: center; /* for pages with flexbox */
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    color: var(--tc_dark_background);
    font-family: var(--title_family);
    text-align: center;
}

#card {
    background-color: var(--bc_surface_accent);
    padding-bottom: 1rem;
    width: 20rem;
    margin: auto; /* for pages without flexbox */
    align-items: center; /* for pages with flexbox */
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    color: var(--tc_dark_background);
    font-family: var(--title_family);
    text-align: center;
}

.card_header {
    font-family: var(--tf_header);
    font-size: 1.5rem;
    padding-top: 1rem;
    color: var(--tc_light_background);
}

.card_text {
    font-family: var(--tf_body);
    font-weight: 500;
    font-size: 0.9rem;
    color: var(--tc_light_background_subtext);
}

.informational_header {
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 0.75;
}

.informational_text {
    margin: 0.8rem;
    font-size: 0.9rem;
}

.informational_spacer {
    padding: 0.85rem;
}

.page_bottom_spacer {
    padding: 2rem;
}

.page_container {
    /*  Same as user_profile_page_box
        Sets up page boundaries and direction */
    display: flex;
    flex-direction: column;
    flex-direction: column;
    justify-content: flex-start;
    width: 20rem;
    margin: auto;
}

.hover_grow_shadow:hover {
    transform: scale(1.0175);
    transition-duration: 0.2s;
    box-shadow: 0 0 11px rgb(235, 235, 235);
}

#upcoming_school_header {
    font-size: 1.2rem;
    margin-bottom: 0rem;
}

#upcoming_school_text {
    font-size: .9rem;
    margin-top: .2rem;
}
.settings_switch_container {
    display: flex;
    padding: 1em;
}

.Checkbox_roomble_checkbox_container__14jnZ {
    display: inline-flex;
    align-items: center;
    background-color: var(--c_logo_royal_blue);
    border-radius: 90000rem;
    
    /* Typography */
    font-family: var(--tf_body);
    font-weight: 600;
    color: var(--tc_dark_background);
}

.Checkbox_roomble_checkbox_container_with_label__3t7sl {
    padding: .5rem 1rem;  /* combined padding-left and padding-right with shorthand */
}

.Checkbox_roomble_checkbox_container__14jnZ input[type="checkbox"] {
    /* space between the checkbox and the label */
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    margin: auto;
    margin-right: 0.6em;
    width: 1rem;
    height: 1rem;
    border: .15rem solid var(--tc_dark_background);
    border-radius: 20%;

    display: grid;
    place-content: center;
}

.Checkbox_roomble_checkbox_container__14jnZ input[type="checkbox"]::before {
    content: "";
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 20%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1rem 1rem var(--tc_dark_background);
}

.Checkbox_roomble_checkbox_container__14jnZ input[type="checkbox"]:checked::before {
    transform: scale(1);
}

:root {
    --transition-cubic-bezier: 0.37s cubic-bezier(.42,0,.25,1);
}

.ToggleSwitch_toggleSwitchContainer__2J3VK {
    position: relative;
    width: 16em;
    height: 3.75em;
    cursor: pointer;
    font-family: var(--tf_body);
    font-weight: 600;
}

.ToggleSwitch_toggleSwitchInput__1Bf3N {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    background-color: var(--bc_landing_box);
    box-shadow: inset rgba(60, 70, 85, 0.3) 0em 0em .75em 0em;
    border-radius: 1em;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: 0.37s cubic-bezier(.42,0,.25,1);
    transition: var(--transition-cubic-bezier);
    cursor: pointer;
}

.ToggleSwitch_toggleSwitchInput__1Bf3N:checked {
    background-color: var(--c_logo_royal_blue);
    transition: 0.37s cubic-bezier(.42,0,.25,1);
    transition: var(--transition-cubic-bezier);
}

.ToggleSwitch_toggleSwitchIcon__2IFv- {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.ToggleSwitch_toggleSwitchIconLeft___C2w7 {
    width: 2.5em;
    margin-left: -1em;
    /*Width and margin should sum to 1.5 to center*/
}

.ToggleSwitch_toggleSwitchIconRight__2SSRr {
    width: 1.5em;
    /*Width and margin should sum to 1.5 to center*/
}

.ToggleSwitch_toggleSwitchHandle__Shyja {
    position: absolute;
    top: .25em;
    left: .25em;
    width: 8em;
    height: 3.25em;
    background-color: white;
    color: var(--tc_light_background);
    border-radius: .75em;
    box-shadow: rgba(60, 70, 85, 0.3) 0em 0em .5em 0em;
    transition: 0.37s cubic-bezier(.42,0,.25,1);
    transition: var(--transition-cubic-bezier);
    display: flex;
    align-items: center;
    justify-content: center;
}

.ToggleSwitch_toggleSwitchInput__1Bf3N:checked ~ .ToggleSwitch_toggleSwitchHandle__Shyja {
    left: calc(100% - 8.25em); /* 100% - (size of switch + right padding) */
    color: var(--c_logo_royal_blue);
}

/* SettingsBox.module.css */
.SettingsBox_container__3rQN2 {
    /* Container for all boxes */
    display: flex; /* Using Flexbox */
    flex-direction: column; /* Stack children vertically */
}

.SettingsBox_box__1sdiE {
    /* Individual box */
    background-color: var(--bc_surface_accent);
    display: flex; /* Using Flexbox */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Space out items */
}

.SettingsBox_topRounded__3M3y5 {
    border-top-left-radius: 1.5em;
    border-top-right-radius: 1.5em;
}

.SettingsBox_bottomRounded__3N3J0 {
    border-bottom-left-radius: 1.5em;
    border-bottom-right-radius: 1.5em;
}

.SettingsBox_divider__1zZAZ {
    margin-bottom: 0.2em;
}

.SettingsBox_title__f8Ho3 {
    font-family: var(--tf_header);
    color: var(--tc_light_background);
    font-size: 1.07rem;
    font-weight: 700;
    margin-left: 1.5em;
}

