.signup_submit_button {
    font-family: var(--text_family);
    font-weight: 600;
    border-radius: 100rem;
    border: solid;
    width: 7.5rem;
    height: 1.9rem;
    color: var(--brand_color);
    background-color: var(--surface_color);
    border: 0.1rem solid var(--brand_color);
    margin: auto;
    margin-top: 0.75rem;
}

.signup_submit_button:disabled {
    color: var(--box_background_color);
    background-color: var(--surface_color);
    border: none;
}

.signup_submit_button:enabled:hover {
    border: 0.1rem solid var(--text_light_color);
    color: var(--text_light_color);
}

.default_roomble_button {
    font-family: var(-tf_body);
    font-weight: 600;
    border: none;
    border-radius: 0.35rem;
    width: 16.75rem;
    height: 2.25rem;
    background-color: var(--bc_active_button);
    color: var(--c_logo_royal_blue);
    margin: auto;
    margin-bottom: 0.5rem;
    transition-duration: 0.1s;
}

.default_roomble_button:disabled {
    background-color: var(--bc_disabled_button);
    color: var(--tc_disabled_button);
}

.default_roomble_button:enabled:hover {
    transform: scale(1.0175);
    transition-duration: 0.2s;
}

.highlighted_roomble_button {
    font-family: var(-tf_body);
    font-weight: 600;
    border: none;
    border-radius: 0.35rem;
    width: 16.75rem;
    height: 2.25rem;
    background-color: var(--bc_card);
    color: var(--bc_surface_accent);
    margin: auto;
    margin-bottom: 0.5rem;
    transition-duration: 0.1s;
}

.highlighted_roomble_button:disabled {
    background-color: var(--bc_disabled_button);
    color: var(--tc_disabled_button);
}

.highlighted_roomble_button:enabled:hover {
    transform: scale(1.0175);
    transition-duration: 0.2s;
}

.default_roomble_input_box {
    background-color: var(--bc_surface);
    color: var(--tc_light_background);
    border-radius: 0.35rem;
    border: none;
    margin-top: 0.5rem;
    width: 16.75rem;
    height: 2.2rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    font-family: var(--tf_body);
    font-weight: 600;
    font-size: 0.9rem;
}

input[type="radio"] {
    appearance: none;
    margin: auto;
    width: 1rem;
    height: 1rem;
    border: 0.15rem solid var(--tc_dark_background);
    border-radius: 50%;

    display: grid;
    place-content: center;
}

input[type="radio"]::before {
    content: "";
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1rem 1rem var(--tc_dark_background);
}

input[type="radio"]:checked::before {
    transform: scale(1);
}

.default_roomble_input_box_error {
    border: 0.095rem solid rgba(255, 0, 0, 0.5);
}

.default_roomble_input_box::placeholder {
    color: var(--tc_light_background_subtext);
}

.default_roomble_input_box:focus {
    outline: none;
}

#highlighted_card {
    background-color: var(--bc_card);
    padding-bottom: 1rem;
    width: 20rem;
    margin: auto; /* for pages without flexbox */
    align-items: center; /* for pages with flexbox */
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    color: var(--tc_dark_background);
    font-family: var(--title_family);
    text-align: center;
}

#card {
    background-color: var(--bc_surface_accent);
    padding-bottom: 1rem;
    width: 20rem;
    margin: auto; /* for pages without flexbox */
    align-items: center; /* for pages with flexbox */
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    color: var(--tc_dark_background);
    font-family: var(--title_family);
    text-align: center;
}

.card_header {
    font-family: var(--tf_header);
    font-size: 1.5rem;
    padding-top: 1rem;
    color: var(--tc_light_background);
}

.card_text {
    font-family: var(--tf_body);
    font-weight: 500;
    font-size: 0.9rem;
    color: var(--tc_light_background_subtext);
}

.informational_header {
    font-size: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 0.75;
}

.informational_text {
    margin: 0.8rem;
    font-size: 0.9rem;
}

.informational_spacer {
    padding: 0.85rem;
}

.page_bottom_spacer {
    padding: 2rem;
}

.page_container {
    /*  Same as user_profile_page_box
        Sets up page boundaries and direction */
    display: flex;
    flex-direction: column;
    flex-direction: column;
    justify-content: flex-start;
    width: 20rem;
    margin: auto;
}

.hover_grow_shadow:hover {
    transform: scale(1.0175);
    transition-duration: 0.2s;
    box-shadow: 0 0 11px rgb(235, 235, 235);
}
