#photo_upload_page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#pics_upload_icon {
    display: none;
}

#photo_upload_submit_button {
    width: 20rem;
    margin-top: .75rem;
}

#upload_photo_button {
    margin-top: .75rem;
    width: 20rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: .1s;
}

#upload_photo_button:hover {
    transform: scale(1.0175);
    transition-duration: .2s;
}

.crop_ui {
    border-radius: .35rem;
}

.crop_ui_box {
    margin: 1rem;
    margin-bottom: -0.5rem;
}

.cropped_thumbnail {
    border-radius: .35rem;
    width: 18rem;
}

.cropped_thumbnail_box {
    margin: 1rem;
    margin-bottom: 0;
}

.photo_upload_spacer {
    padding: 1rem;
}