/* SettingsBox.module.css */
.container {
    /* Container for all boxes */
    display: flex; /* Using Flexbox */
    flex-direction: column; /* Stack children vertically */
}

.box {
    /* Individual box */
    background-color: var(--bc_surface_accent);
    display: flex; /* Using Flexbox */
    align-items: center; /* Center items vertically */
    justify-content: center; /* Space out items */
}

.topRounded {
    border-top-left-radius: 1.5em;
    border-top-right-radius: 1.5em;
}

.bottomRounded {
    border-bottom-left-radius: 1.5em;
    border-bottom-right-radius: 1.5em;
}

.divider {
    margin-bottom: 0.2em;
}

.title {
    font-family: var(--tf_header);
    color: var(--tc_light_background);
    font-size: 1.07rem;
    font-weight: 700;
    margin-left: 1.5em;
}
