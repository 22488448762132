#no_swipes_available_page {
    font-family: var(--title_family);
    display: flex;
    justify-content: center;
}

#no_swipes_available_box {
    background-color: var(--box_background_color);
    border-radius: 1.25rem;
    width: 20rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

#no_swipes_available_text {
    color: var(--text_light_color);
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
}

#loader_outer_box {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 75vh; /*center the loader vertically by adjusting height of page*/
}

.filter_gradyear_checkboxes_container {
    display: flex;
    flex-wrap: wrap;  /* Allows the child items to wrap to the next row when they exceed the container's width */
    justify-content: center;  /* Spreads the child items out evenly */
    width: 100%;  /* Can adjust based on your needs */
}

.filter_gradyear_checkboxes_item {
    flex: 0 0 48%;  /* This makes sure that each checkbox takes up roughly half of the container's width minus some space for padding/margin */
    margin-bottom: 20px;  /* Space between rows */
}