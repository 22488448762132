#basic_info_spacer {
    margin: 1rem;
}

#basic_info_alert {
    border: none;
    width: 17.5rem;
    font-family: var(--tf_body);
    margin: auto;
    margin-top: .5rem;
    margin-bottom: .5rem;
    /* border-radius: .75rem; */
}
#basic_info_subtext {
    margin-bottom: .5rem;
}

#basic_info_helptext {
    margin-top: .5rem;
    margin-bottom: 0;
    padding: 0;
}