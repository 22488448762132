.roomble_checkbox_container {
    display: inline-flex;
    align-items: center;
    background-color: var(--c_logo_royal_blue);
    border-radius: 90000rem;
    
    /* Typography */
    font-family: var(--tf_body);
    font-weight: 600;
    color: var(--tc_dark_background);
}

.roomble_checkbox_container_with_label {
    padding: .5rem 1rem;  /* combined padding-left and padding-right with shorthand */
}

.roomble_checkbox_container input[type="checkbox"] {
    /* space between the checkbox and the label */
    appearance: none;
    margin: auto;
    margin-right: 0.6em;
    width: 1rem;
    height: 1rem;
    border: .15rem solid var(--tc_dark_background);
    border-radius: 20%;

    display: grid;
    place-content: center;
}

.roomble_checkbox_container input[type="checkbox"]::before {
    content: "";
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 20%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1rem 1rem var(--tc_dark_background);
}

.roomble_checkbox_container input[type="checkbox"]:checked::before {
    transform: scale(1);
}
