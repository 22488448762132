#lifestyle_survey_page {
    width: 20rem;
    margin: auto;
}

.lifestyle_prefs_title_box {
    display: flex;
}

.lifestyle_prefs_title {
    color: var(--tc_dark_background);
    text-align: center;
    margin: auto;
    margin-top: .3rem;
    width: 18rem;
    font-size: 1.05rem;
}

#lifestyle_submit_button {
    margin-top: 2rem;
}

#lifestyle_question_count_label_full_list {
    text-align: right;
    margin-right: 2rem;
    margin-top: .3rem;
    margin-bottom: -.75rem;
    font-size: .7rem;
}

#lifestyle_question_count_label_short_list {
    font-size: .8rem;
    font-weight: 600;
}

#lifestyle_weights_outer_box {
    margin-top: 1rem;
}

#lifestyle_horizontal_box {
    display: flex;
    justify-content: space-between;
    width: 17rem;
    margin: auto;
    margin-top: .65rem;
}

#lifestyle_radio_button_label_box {
    display: flex;
    flex-direction: column;
}

#lifestyle_radio_label {
    font-size: .9rem;
    font-family: 'Inter';
    font-weight: 600;
    margin-top: .175rem;
}

#lifestyle_arrows_question_count_box {
   display: flex;
   justify-content: space-around;
   align-items: center;
   width: 20rem;
   margin-top: 1.5rem;
   margin-bottom: -1.25rem;
   font-family: 'Inter';
}

.lifestyle_arrow_box {
    padding: .5rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    border: none;
    background-color: transparent;
    color: var(--tc_dark_background);
    font-size: 2rem;
    font-weight: 600;
}

.lifestyle_arrow_box_highlighted {
    color: var(--tc_dark_background);
}

.lifestyle_arrow_box_disabled {
    color: var(--c_logo_royal_blue);
}