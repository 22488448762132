#login_page {
    background-color: transparent;
}

#entrance_page {
    background-color: transparent;
}

#sign_in_card_arrangement {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#sign_in_page_arrangement {
    display: flex;
    flex-direction: column;
    align-items: center; 
    justify-content: flex-start;
}

.signup_title {
    font-family: var(--tf_header);
    color: var(--tc_dark_background);
    font-size: 1.5rem;
    padding-top: 1rem;
}

#signin_input_fields {
    margin-top: .5rem;
    margin-bottom: 1.2rem;
}

.signup_subtext {
    font-family: var(--tf_body);
    font-weight: 500;
    font-size: .9rem;
    color: var(--tc_dark_background_subtext);
}

#termsconditions_outer {
    margin-left: 2rem;
    margin-right: 1rem;
    text-align: left;
    display: flex;
    margin-bottom: 1rem;
}

#termsconditions_text {
    margin-left: .5rem;
}

.termsconditions_checkbox {
    border: none;
}

.termsconditions_links {
    text-decoration: none;
    color: var(--tc_dark_background);
    font-weight: 600;
}

.termsconditions_links:hover {
    text-decoration: underline;
    color: var(--tc_dark_background);
}

#signin_switch_pages_text {
    margin-top: .75rem;
    font-family: var(--tf_body);
    font-weight: 600;
    color: var(--tc_dark_background)
}

#signin_switch_pages_link {
    color: var(--c_logo_royal_blue);
    font-weight: 600;
    background-color: transparent;
    border: none;
}

#signin_switch_pages_link:hover {
    text-decoration: underline;
}

#signin_help_text {
    margin-top: 0;
    font-family: var(--tf_body);
    font-weight: 600;
    color: var(--tc_dark_background)
}

#signin_help_link {
    color: var(--c_logo_royal_blue);
    font-weight: 600;
    text-decoration: none;
}

#signin_help_link:hover {
    text-decoration: underline;
}

#signin_logo {
    width: 3.5rem;
    height: 3.5rem;
    margin-bottom: 1.5rem;
}

.signin_alert {
    width: 20rem;
    border: none;
}

#login_prompt_box {
    background-color: var(--box_background_color);
    text-align: center;
    height: 7rem;
    width: 20rem;
    margin: auto;
    border-radius: 1.25rem;

    margin-top: 2rem;
}

#login_prompt_title {
    font-family: var(--title_family);
    color: var(--text_light_color);
    font-size: 1.3rem;
    padding-top: 1rem;
}

.lifestyle_prefs_title {
    font-family: var(--title_family);
    font-size: 1rem;
    padding-top: 1rem;
    padding-left: .5rem;
    padding-right: .5rem;
}

.signup_input_box {
    background-color: var(--surface_color);
    color: var(--text_light_color);
    border-radius: .4rem;
    border: .095rem solid var(--outline_color);
    margin-top: .5rem;
    width: 15rem;
    height: 2.2rem;
    padding-left: .75rem;
    padding-right: .75rem;

    font-family: var(--text_family);
    font-weight: 600;
    font-size: .9rem;
}

.signup_input_box:disabled {
    color: var(--box_background_color);
    border-color: var(--surface_color);
}

.signup_input_box_error {
    border: .095rem solid rgba(255, 0, 0, 0.5);
}

.signup_input_box::placeholder {
    color: var(--dark_gray);
}

.signup_input_box:focus {
    outline: none;
    border: .1rem solid var(--brand_color);
}

#signup_login_despacer {
    margin-top: -1.5rem;
}
