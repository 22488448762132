@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600&family=Poppins:wght@700&display=swap");
/* Used Google bc Adobe doesn't have Inter */
@import url("https://use.typekit.net/noz0emw.css");
/* Aidan's UCLA adobe fonts account */

:root {
  /* deprecated colors */
  --surface_color: #376F87;
  --outline_color: #295365;
  --box_background_color_dark: #2e5c70;
  --box_background_color: #3883A3;
  --white_hover_color: #FBFBFB;
  --text_light_color: #FFFFFF;
  --brand_color: #93D4F0;
  --landing_dark_blue: #2C5B6F;
  --landing_dark_blue_light: #3A7D94;
  --dark_gray: #cccccc;
  --dark_gray_2: #858585;
  --title_family: 'Poppins';
  --logo_family: 'Aller';
  --text_family: 'Inter';

  /* c_ indicates color */
  --c_logo_navy: #2C5B6F;
  --c_logo_royal_blue: #29ABE2;
  --c_red_negative: #C74945;
  --c_red_negative_highlighted: #C74F4C;
  --c_green_positive: #5AA364;
  --c_highlight_yellow: #FFF200;

  /* bc is background color */
  --bc_surface: #F5F5F7;
  --bc_surface_accent: #FFFFFF;
  --bc_card: #29ABE2;
  --bc_active_button: #FFFFFF; /* tied to bc_surface_accent */
  --bc_hover_button: #F5F5F7; /* tied to bc_surface */
  --bc_disabled_button: #93C9E4;
  --bc_landing_box: #F2F6F7;

  /* tc_ is text color */
  --tc_light_background: #000000;
  --tc_light_background_subtext: #6E6E73;
  --tc_dark_background: #FFFFFF;
  --tc_dark_background_subtext: #E4F2F9;
  --tc_dark_background_disabled: #CCCCCC;
  --tc_disabled_button: #5DAFD6;
  --tc_landing_light_background: #888E9D;

  /* tf_ is text font family */
  --tf_header: 'Poppins';
  --tf_logo: 'Aller';
  --tf_body: 'Inter';

  /* custom colors */
  --landing_textbox_border: #cccccc;
}