#top_navbar {
    background-color: var(--bc_surface_accent);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    width: 100%;
    z-index: 100;
}

.top_navbar_item {
    color: var(--tc_light_background_subtext);
    font-family: var(--text_family);
    font-weight: 600;
    text-decoration: none;
    font-size: .9rem;
    justify-self: center;  

    margin: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
    transition-duration: .30s;
}

.top_navbar_item:hover {
    color: var(--tc_light_background_subtext);
    transform: translate(0, -0.15rem);
    transition-duration: .30s;
  }

#top_navbar_brand_text {
    color: var(--c_logo_royal_blue);
    text-decoration: none;
    font-family: var(--tf_logo);
    font-size: 2rem;

    justify-self: center;    
    margin-left: 1rem;
    margin-right: 1rem;   
}

#top_navbar_space {
    padding-top: 6rem;
}

#bottom_navbar {
    background-color: var(--bc_surface_accent);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right:0;
    z-index: 100;
}

.bottom_navbar_svg {
    width: 2.25rem;
    height: 2.25rem;
    margin: .5rem;
    margin-left: 2rem;
    margin-right: 2rem;
    transition-duration: .20s;
    /* stroke: var(--c_logo_royal_blue);
    fill: var(--c_logo_royal_blue);
    color: var(--c_logo_royal_blue) */
}

.bottom_navbar_svg:hover {
    color: var(--c_logo_royal_blue);
    transform: translate(0, -0.15rem);
    transition-duration: .20s;
}

#bottom_navbar_space {
    padding: 2rem;
}

#top_header {
    background-color: var(--bc_surface_accent);
    text-align: center;
    padding: .8rem;
    width: 100%;
    z-index: 100;
    margin-bottom: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

#header_logo {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: .5rem;
}

#top_header_text {
    font-family: var(--tf_logo);
    color: var(--c_logo_royal_blue);
    font-size: 2rem;
}

#footer_absolute {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    padding: .4rem;
    color: var(--c_logo_navy);
    font-family: var(--tf_body);    
}


#footer_relative {
    width: 100%;
    text-align: center;
    padding: .4rem;
    color: var(--c_logo_navy);
    font-family: var(--tf_body); 
}

#footer_random {
    font-size: .9rem;
    font-weight: 600;
}

#footer_body {
    font-size: .85rem;
    font-weight: 550;
}