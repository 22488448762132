.burger {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 1.5em;
  width: 2.5em;
  z-index: 3;
}

.burger_line {
  width: 1.9em;
  height: .22em;
  background-color: var(--tc_light_background_subtext);
  transition: all 0.3s ease-in-out;
  z-index: 3;
}

.burger.open .burger_line:nth-child(1) {
  transform: translateY(.6375em) rotate(45deg);
}

.burger.open .burger_line:nth-child(2) {
  opacity: 0;
}

.burger.open .burger_line:nth-child(3) {
  transform: translateY(-.6375em) rotate(-45deg);
}

.menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--bc_surface_accent);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  overflow-x: hidden;
  transform: translateX(-100%);
  transition: 0.5s;
  padding-top: 60px;
}

.menu.open {
  transform: translateX(0);
}

.menu a {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  color: var(--tc_light_background_subtext);
  text-decoration: none;
  transition: 0.3s;
  margin-top: 20px;
}

.menu a:hover {
  color: var(--c_logo_royal_blue);
}

.no-scroll {
  overflow: hidden;
}