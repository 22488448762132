@import "constants.css";

#matches {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: auto;
  width: 100%;
}

#no_matches_page {
  font-family: var(--title_family);
  display: flex;
  justify-content: center; 
}

#no_matches_box {
  background-color: var(--box_background_color);
  border-radius: 1.25rem;
  width: 20rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: fixed;
}

#no_matches_text {
  color: var(--text_light_color);
  padding-left: 1rem;
  padding-right: 1rem;
  text-align: center;
}

#match_card_button {
  border: none;
  margin: auto;
  background-color: var(--bc_surface);
  margin-bottom: .5rem;
}

.match_preview_box {
  width: 20rem;
  height: 7.25rem;
  border-radius: 10rem;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  background-color: var(--bc_surface_accent);
  margin: auto;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.match_preview_photo {
  border-radius: .75rem;
  width: 7.25rem;
  height: auto
}

.match_preview_name {
  color: var(--tc_light_background);
  font-family: var(--tf_header);
  font-size: 1.1rem;
}

.match_preview_subtext {
  color: var(--tc_light_background_subtext);
  font-family: var(--tf_header);
  font-size: 0.9rem;
}

.match_preview_date {
  color: var(--tc_light_background_subtext);
  font-family: var(--tf_header);
  font-size: .75rem;
}

.match_preview_text {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  align-content: flex-start;
}

.match_preview_text_top {
  justify-self: flex-start;
  margin-top: .5rem;
}

.match_preview_text_bottom {
  justify-self: flex-end;
  margin-bottom: .5rem;
}

.matches_title_box {
  margin-bottom: 1rem;
}