@import "constants.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue' 'PT Sans',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  background-color: var(--bc_surface);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App_image_background { /*conditionally render this and another background image based on if the page needs to scroll or how the image works*/
  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: url(LandingPageBackground.svg);
  background-size: cover;
  background-attachment: fixed;
  position: fixed;
  background-position: center center;
  margin: 0;
}

.App_color_background {
  z-index: -1;
  background-color: var(--bc_surface);
  background-position: center center;
  margin: 0;
}

.App_swirl_background {
  background-color: var(--bc_surface);
  background-position: center center;
  margin: 0; 
  height: 100%;
  width: 100%;
  z-index: -1;
  background-image: url(DiagonalSwirl.svg);
  background-size: cover;
  background-attachment: fixed;
  position: fixed
}
