#user_profile_page_box {
    display: flex;
    flex-direction: column;
    flex-direction: column;
    justify-content: flex-start;
    width: 20rem;
    margin: auto;
}

.user_profile_image_box {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 0.75rem;
    z-index: 1;
    position: relative;
    margin-bottom: -0.75rem;
}

#user_profile_name {
    font-family: var(--tf_header);
    font-weight: 700;
    font-size: 1.3rem;
    color: var(--c_logo_royal_blue);
}

#user_profile_pronouns_age {
    font-family: var(--tf_header);
    font-weight: 700;
    font-size: 1.3rem;
    color: var(--tc_light_background_subtext);
}

.user_profile_box {
    background-color: var(--bc_surface_accent);
    border-radius: 0.75rem;
    padding: 0.5rem;
    margin-bottom: 1rem;
}

#swipe_button_box {
    background-color: transparent;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    margin-top: -0.5rem;
}

.user_profile_title {
    font-family: var(--tf_header);
    color: var(--tc_light_background);
    font-weight: 700;
    font-size: 1.07rem;
}

.user_profile_subtext {
    font-family: var(--tf_body);
    font-weight: 600;
    color: var(--tc_light_background_subtext);
}

.user_profile_edit_button {
    font-family: var(--tf_body);
    font-size: 1rem;
    text-decoration: none;
}

.user_profile_edit_button_unfilled {
    color: var(--c_red_negative);
}

.user_profile_edit_button_unfilled:hover {
    color: var(--c_red_negative_highlighted);
}

.user_profile_edit_button_filled {
    color: var(--tc_light_background_subtext);
}

.user_profile_edit_button_filled:hover {
    color: var(--tc_light_background);
}

#user_profile_photo_upload_edit_button {
    align-self: flex-end;
    font-weight: 600;
    z-index: 5;
    margin-bottom: 0.1rem;
    margin-top: 0.75rem;
}

#lifestyle_survey_display_box {
    margin-bottom: 1rem;
}

#lifestyle_survey_display_q {
    font-family: var(--tf_body);
    font-weight: 600;
    color: var(--tc_light_background);
    margin-top: 0.1rem;
}

.lifestyle_survey_display_text {
    font-family: var(--tf_body);
    font-weight: 600;
    color: var(--tc_light_background_subtext);
}

#swipe_box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
}

#like_button {
    width: 4rem;
    transition-duration: 0.2s;
}

#like_button:hover {
    transition-duration: 0.2s;
    transform: scale(1.03);
}

#dislike_button {
    width: 4rem;
    transition-duration: 0.2s;
}

#dislike_button:hover {
    transition-duration: 0.2s;
    transform: scale(1.03);
}

.swipe_buttons {
    margin-top: 0.3rem;
    margin-bottom: 0.3rem;
}

#swipe_page_profile_space {
    padding: 1rem;
}

.dislike_button_wrapper {
    border: none;
    padding: 0;
    background-color: var(--surface-color);
    margin-left: 2.5rem;
}

.like_button_wrapper {
    border: none;
    padding: 0;
    background-color: var(--surface-color);
    margin-right: 2.5rem;
}

#user_profile_back_button {
    border: none;
    color: var(--tc_light_background_subtext);
    font-weight: 500;
    background-color: var(--surface-color);
    font-family: var(--tf_body);
}

#swipe_animation_wrapper {
    margin: auto;
    width: 20rem;
    z-index: 100;
}

.user_profile_image_no_swipe {
    background-color: transparent;
    position: absolute;
    z-index: 2;
}

.user_profile_image_swipe_right {
    background-color: #5aa364;
    padding-left: 10rem;
    padding-right: 10rem;
    margin-top: -10rem;
    padding-bottom: 100rem;
    position: absolute;
    z-index: 2;
    animation: swipe 0.6s ease-out;
    border-radius: 0.5rem;
}

.user_profile_image_swipe_left {
    background-color: var(--bc_surface);
    padding-left: 10rem;
    padding-right: 10rem;
    padding-bottom: 100rem;
    position: absolute;
    z-index: 2;
    animation: swipe 0.6s ease-out;
    border-radius: 0.5rem;
}

@keyframes swipe {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.user_profile_image_swipe_match {
    background-color: var(--c_logo_royal_blue);
    padding-left: 10rem;
    padding-right: 10rem;
    padding-bottom: 100rem;
    position: absolute;
    z-index: 2;
    animation: match-swipe 2s ease-out;
    border-radius: 0.5rem;
}

.user_profile_match_text_hidden {
    display: none;
}

.user_profile_match_text {
    display: block;
    position: absolute; /* Use absolute positioning to move the text freely */
    top: 50%; /* Center the text vertically */
    left: 50%; /* Start the text from the center */
    transform: translate(-50%, -50%); /* Ensure the text is centered */
    animation: match-text-slide 2s ease-out; /* Use the animation */
    z-index: 3; /* Make sure the text is on top of other elements */
    font-size: 2em; /* Adjust the font size as needed */
    color: white; /* Adjust the color as needed */

    font-family: var(--tf_header);
    font-weight: 600;
}

@keyframes match-swipe {
    0% {
        opacity: 0;
    }

    20% {
        opacity: 1;
    }

    70% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

@keyframes match-text-slide {
    0% {
        color: var(--bc_surface);
        opacity: 0;
        top: -250%;
    }

    35% {
        color: var(--bc_surface_accent);
        opacity: 1;
        top: 50%;
    }

    60% {
        color: var(--bc_surface_accent);
        opacity: 1;
        top: 50%;
    }

    100% {
        color: var(--bc_surface);
        opacity: 0;
        top: 200%;
    }
}

#user_profile_color_overlay {
    border-radius: 0.25rem;
}

#survey_switch_button {
    border: none;
    background-color: var(--bc_surface_accent);
    font-family: var(--tf_body);
    color: var(--tc_light_background_subtext);
    font-weight: 500;
    text-decoration: underline;
}

#survey_switch_button_alt {
    border: none;
    background-color: var(--bc_surface_accent);
    font-family: var(--tf_body);
    color: var(--tc_light_background);
    font-weight: 500;
}

#choose_filter_button {
    width: 10rem;
    height: 2rem;
    margin: auto;
    margin-bottom: 1rem;
    margin-top: -1.25rem;
    border: none;
    border-radius: 0.75rem;
    background-color: var(--bc_surface_accent);
    color: var(--tc_light_background_subtext);
    font-family: "Inter";
    font-weight: 800;
    letter-spacing: 0.01rem;
}

#feedback_button {
    padding-left: 1rem;
    padding-right: 1rem;
    height: 2rem;
    margin: auto;
    border: none;
    border-radius: 0.75rem;
    background-color: var(--bc_surface_accent);
    color: var(--tc_light_background_subtext);
    font-family: "Inter";
    font-weight: 500;
    letter-spacing: 0.01rem;
}

.progress_bar_background {
    width: 19rem;
    height: 0.6rem;
    margin: auto;
    border-radius: 20rem;
    background-color: var(--bc_disabled_button);
    z-index: 1;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.progress_bar_completion {
    height: 0.6rem;
    border-radius: 20rem;
    background-color: var(--c_logo_royal_blue);
    z-index: 2;
}

.progress_bar_title {
    display: flex;
    justify-content: space-between;
}

.profile_two_column {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 1rem;
    gap: 1rem;
}

.settings_button_container {
    background-color: var(--bc_surface_accent);
    padding: 0.75rem;
    border-radius: 0.75rem;
    transition-duration: 0.2s;
}

.refer_friend_button {
    background-color: var(--bc_surface_accent);
    font-family: var(--tf_body);
    font-weight: 600;
    color: var(--tc_light_background_subtext);
    width: 100%;
    border-radius: 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: 0.2s;
    cursor: pointer;
}
