:root {
    --transition-cubic-bezier: 0.37s cubic-bezier(.42,0,.25,1);
}

.toggleSwitchContainer {
    position: relative;
    width: 16em;
    height: 3.75em;
    cursor: pointer;
    font-family: var(--tf_body);
    font-weight: 600;
}

.toggleSwitchInput {
    appearance: none;
    background-color: var(--bc_landing_box);
    box-shadow: inset rgba(60, 70, 85, 0.3) 0em 0em .75em 0em;
    border-radius: 1em;
    width: 100%;
    height: 100%;
    position: absolute;
    transition: var(--transition-cubic-bezier);
    cursor: pointer;
}

.toggleSwitchInput:checked {
    background-color: var(--c_logo_royal_blue);
    transition: var(--transition-cubic-bezier);
}

.toggleSwitchIcon {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.toggleSwitchIconLeft {
    width: 2.5em;
    margin-left: -1em;
    /*Width and margin should sum to 1.5 to center*/
}

.toggleSwitchIconRight {
    width: 1.5em;
    /*Width and margin should sum to 1.5 to center*/
}

.toggleSwitchHandle {
    position: absolute;
    top: .25em;
    left: .25em;
    width: 8em;
    height: 3.25em;
    background-color: white;
    color: var(--tc_light_background);
    border-radius: .75em;
    box-shadow: rgba(60, 70, 85, 0.3) 0em 0em .5em 0em;
    transition: var(--transition-cubic-bezier);
    display: flex;
    align-items: center;
    justify-content: center;
}

.toggleSwitchInput:checked ~ .toggleSwitchHandle {
    left: calc(100% - 8.25em); /* 100% - (size of switch + right padding) */
    color: var(--c_logo_royal_blue);
}
